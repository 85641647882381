<template>
    <div class="module-container">
        <div class="content-container">
            <div class="content">
                <component :is="name"></component>
            </div>
        </div>
        <popup
            :isError="isError"
            :isSuccess="isSuccess"
            :isWarning="isWarning"
            :text="popupText"
            :accountPresence="accountPresence"
        ></popup>
    </div>
</template>

<script>
import Login from "@/components/dmLogin/login.vue";
import Register from "@/components/dmLogin/register.vue";
import resetPassword from "@/components/dmLogin/resetPassword.vue";
import Popup from "@/components/popup.vue";
import { mapGetters } from "vuex";

export default {
    name: "loginModule",
    components: { Login, Register, resetPassword, Popup },
    props: {
        name: {
            type: String,
            default: "login",
        },
    },
    data() {
        return {
            popupText: "",
            isError: false,
            isSuccess: false,
            isWarning: false,
            accountPresence: false,
        };
    },
    computed: {
        ...mapGetters(["systemHasLogin"]),
    },
    methods: {},
    mounted() {
        if (this.systemHasLogin && this.name != "resetPassword") {
            this.$navigateTo({ text: "已登录" }, "/personal");
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/dm/loginIndex.scss";
@import "@/assets/scss/dm/loginModule.scss";
</style>
