<template>
    <div class="load-continer">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
    </div>
</template>

<script>
export default {
    name: "customLoading",
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.load-continer {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include rowGap(10px);
    @media (min-width: 500px) {
        min-width: 500px;
    }
}
p {
    border-radius: 6px;
    width: 6px;
    height: 40px;
    background: #6086e8;
    &:nth-of-type(1),
    &:nth-of-type(5) {
        animation: line-load 1s ease-in-out infinite;
    }
    &:nth-of-type(2),
    &:nth-of-type(4) {
        animation: line-load 1s ease-in-out infinite;
        animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
        animation: line-load-reverse 1s ease-in-out infinite;
    }
}
@keyframes line-load {
    0% {
        height: 40px;
    }
    50% {
        height: 20px;
    }
    100% {
        height: 40px;
    }
}
@keyframes line-load-reverse {
    0% {
        height: 20px;
    }
    50% {
        height: 40px;
    }
    100% {
        height: 20px;
    }
}
</style>
