<template>
    <div class="login" ref="container">
        <div class="container">
            <login-left></login-left>
            <component :is="name"></component>
        </div>
    </div>
</template>

<script>
import Login from "@/components/vhLogin/index.vue";
import Register from "@/components/vhLogin/register.vue";
import Forget from "@/components/vhLogin/forget.vue";
import Update from "@/components/vhLogin/update.vue";

export default {
    name: "loginContainer",
    props: {
        name: {
            type: String,
            default: "Login",
        },
    },
    components: { Login, Register, Forget, Update },
    methods: {
        changePage(name) {
            const data = {
                identifier: "changeHref",
                page: name,
            };
            window.parent.postMessage(data, process.env.VUE_APP_PARENT);
        },
    },
    mounted() {
        let timer = setTimeout(() => {
            const data = {
                identifier: "changeHeight",
                h: this.$refs.container.offsetHeight,
            };
            window.parent.postMessage(data, process.env.VUE_APP_PARENT);
            clearTimeout(timer);
        }, 100);
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/login.scss";
</style>
