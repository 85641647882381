import { Message } from "element-ui";

export function showMessage(message, type, time = 3000) {
    // 关闭之前的提示框
    Message.closeAll();
    Message({
        message: message,
        type: type,
        duration: time,
    });
}
