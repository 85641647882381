<template>
    <div class="personal-subscription">
        <template v-if="!isTrial && subscriptions.length != 0">
            <div class="subscriptions-container">
                <div v-for="(s, index) in subscriptions" :key="index">
                    <div
                        class="card"
                        v-if="!judgeState('notSubscribed', s)"
                        :data-payment-method="s.payment_method"
                        :data-card-num="s.card_num"
                    >
                        <div
                            class="card-top"
                            :class="
                                judgeState('stopOrCancel', s) ? 'bg-red' : ''
                            "
                        >
                            <img
                                loading="lazy"
                                :src="s.icon"
                                alt="product logo"
                            />
                            <p class="name">{{ s.plan_name }}</p>
                            <p
                                v-if="judgeState('normal', s)"
                                class="p-btn usable subscription-btn manage-top"
                                @click="toManage($event, s, s.can_manage == 2)"
                                :class="s.can_manage == 2 ? 'disabled' : ''"
                            >
                                {{
                                    s.can_manage == 2
                                        ? $t("subscription.being_processed")
                                        : $t("subscription.manage_btn")
                                }}
                            </p>
                            <p
                                v-if="judgeState('isStop', s)"
                                class="p-btn active renew-top"
                                @click="
                                    renewSub(
                                        s.order_products_id,
                                        s.is_expired,
                                        s.currency
                                    )
                                "
                                :class="isRecover ? 'isLoading' : ''"
                            >
                                {{ $t("subscription.renew") }}
                            </p>
                            <p
                                v-if="s.isFamily"
                                class="p-btn gray-btn"
                                @click="leaveSubscription"
                            >
                                {{ $t("subscription.leave") }}
                            </p>
                        </div>
                        <div class="card-main" v-if="s.isFamily">
                            <div>
                                <p class="attribute-name">
                                    {{ $t("subscription.expire_date") }}:
                                </p>
                                <p class="attribute-value">{{ s.date }}</p>
                            </div>
                            <div>
                                <p class="attribute-name">
                                    {{ $t("subscription.status_text") }}:
                                </p>
                                <p class="attribute-value">Share</p>
                            </div>
                        </div>
                        <div class="w100" v-else>
                            <p
                                class="cancel-tip"
                                v-if="judgeState('stopOrCancel', s)"
                            >
                                {{
                                    judgeState("isStop", s)
                                        ? $t("subscription.stop_text", {
                                              plan: s.name,
                                          })
                                        : $t("subscription.cancel_text", {
                                              plan: s.name,
                                          })
                                }}
                            </p>
                            <div class="card-main">
                                <div>
                                    <p class="attribute-name">
                                        {{ $t("subscription.purchase_date") }}:
                                    </p>
                                    <p class="attribute-value">
                                        {{ s.subscribe_start_at }}
                                    </p>
                                </div>
                                <div>
                                    <p class="attribute-name">
                                        {{ $t("subscription.end_date") }}:
                                    </p>
                                    <p class="attribute-value">
                                        {{
                                            judgeState("stopOrCancel", s) ||
                                            judgeState("overdue", s) ||
                                            s.sst_id == 9
                                                ? "-"
                                                : s.subscribe_end_at
                                        }}
                                    </p>
                                </div>
                                <div>
                                    <p class="attribute-name">
                                        {{ $t("subscription.price") }}:
                                    </p>
                                    <p class="attribute-value">
                                        {{ s.format_final_price }}
                                    </p>
                                </div>
                                <!-- 支持设备数 -->
                                <div
                                    v-if="
                                        !(
                                            judgeState('expired', s) ||
                                            s.pay_type == 1
                                        )
                                    "
                                >
                                    <p class="attribute-name">
                                        {{ $t("subscription.num") }}:
                                    </p>
                                    <p class="attribute-value-list">
                                        <span
                                            v-for="(
                                                v, k
                                            ) in s.device_total_use_num"
                                            :key="k"
                                        >
                                            {{ v.use || 0 }} / {{ v.total }}
                                            <span
                                                class="p-a"
                                                @click="
                                                    viewDetail(
                                                        s.id,
                                                        vendorInfo.planSearch
                                                            ? s.plan_name
                                                            : s.name,
                                                        s.ss_id
                                                    )
                                                "
                                            >
                                                &nbsp;
                                                {{
                                                    $t(
                                                        "subscription.see_detail"
                                                    )
                                                }}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p class="attribute-name">
                                        {{ $t("subscription.account_status") }}:
                                    </p>
                                    <p
                                        class="attribute-value"
                                        :class="
                                            judgeState('stopOrCancel', s)
                                                ? 'red-text'
                                                : ''
                                        "
                                    >
                                        {{
                                            s.active_status == 3
                                                ? (s.sst_id == 9
                                                      ? $t(
                                                            "manageSubscription.life_time"
                                                        )
                                                      : s.subscribe_end_at) +
                                                  $t("subscription.overdue")
                                                : accountStatus[s.active_status]
                                        }}
                                    </p>
                                </div>
                                <div v-if="s.card_num">
                                    <p class="attribute-name">
                                        {{ $t("subscription.payment_detail") }}:
                                    </p>
                                    <p class="attribute-value-list">
                                        <span>
                                            <img
                                                v-if="
                                                    !vendorInfo.personalizedDisplay
                                                "
                                                loading="lazy"
                                                :src="s.bank_icon"
                                                alt="card icon"
                                            />
                                            <span> {{ s.card_num }}</span>
                                        </span>
                                        <span
                                            v-if="
                                                judgeState('normal', s) &&
                                                !vendorInfo.personalizedDisplay
                                            "
                                            class="p-a"
                                            @click="
                                                handleChangePayment(
                                                    $event,
                                                    s.order_products_id,
                                                    s.currency,
                                                    s.sst_id == 9
                                                )
                                            "
                                        >
                                            {{
                                                $t(
                                                    "subscription.change_payment"
                                                )
                                            }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="isAdmin">
                                <p>
                                    支付平台:
                                    {{
                                        s.order
                                            ? s.order.payment_platform ||
                                              s.order.payment_method
                                            : s.payment_method
                                    }}
                                </p>
                                <p>
                                    注册码:{{
                                        s.order ? s.order.license_code : ""
                                    }}
                                </p>
                                <p>
                                    订单id:
                                    {{
                                        s.order ? s.order.order_id : s.order_no
                                    }}
                                </p>
                                <p></p>
                                <p>
                                    订阅id:
                                    {{ s.order ? s.order.subscribe_id : s.id }}
                                </p>
                            </div>
                            <p
                                v-if="judgeState('normal', s)"
                                class="p-btn usable subscription-btn manage-bottom"
                                @click="toManage($event, s, s.can_manage == 2)"
                                :class="s.can_manage == 2 ? 'disabled' : ''"
                            >
                                {{
                                    s.can_manage == 2
                                        ? $t("subscription.being_processed")
                                        : $t("subscription.manage_btn")
                                }}
                            </p>
                            <p
                                v-if="judgeState('isStop', s)"
                                class="p-btn active renew-bottom"
                                @click="
                                    renewSub(
                                        s.order_products_id,
                                        s.is_expired,
                                        s.currency
                                    )
                                "
                                :class="isRecover ? 'isLoading' : ''"
                            >
                                {{ $t("subscription.renew") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination
                layout="prev, pager, next"
                :total="totalCount"
                :page-size="10"
                :current-page="currentPage"
                @current-change="handlePageChange"
            >
            </el-pagination>
        </template>
        <div v-if="isTrial" class="card trial-tip">
            <p>{{ $t("subscription.is_trial") }}</p>
            <a :href="storeUrl" title="store" target="_blank">
                <p class="p-btn active">
                    {{ $t("subscription.start_subscription") }}
                </p>
            </a>
        </div>
        <p class="cut-off-line" v-if="isTrial && isRecommend"></p>
        <div class="recommend-container" v-if="isRecommend">
            <p>
                {{
                    subscriptions.length == 0
                        ? $t("subscription.no_subscription")
                        : ""
                }}{{ $t("subscription.recommend") }}
            </p>
            <div class="recommend-products">
                <div
                    class="a-product"
                    v-for="(p, i) in recommendProducts"
                    :key="i"
                >
                    <a
                        style="display: none"
                        :ref="`downloadLink${i}`"
                        :href="p.download_url"
                    ></a>
                    <img loading="lazy" :src="p.icon" alt="product logo" />
                    <div>
                        <p class="name">{{ p.name }}</p>
                        <div class="btn-container recommend-btn-container">
                            <a
                                :href="p.store_url"
                                target="_blank"
                                :title="p.name"
                            >
                                <p class="p-btn gray tobtn">
                                    {{ $t("subscription.learn_more") }}
                                </p>
                            </a>
                            <a :href="p.download_url" title="download">
                                <p class="p-btn gray bottom-btn">
                                    {{ $t("subscription.free_download") }}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <choose-card
            v-if="isChoosePayment"
            :source="currentSource"
            :showValue="isChoosePayment"
            ref="cardsComponent"
        ></choose-card>
    </div>
</template>

<script>
import {
    getSubscription,
    getRecommendation,
    operatSub,
    querySubscription,
} from "@/api/subscription.js";
import { mapGetters } from "vuex";
import chooseCard from "@/components/chooseCardComponent.vue";
import { changePayment } from "@/api/subscription.js";

export default {
    name: "subscriptionComponent",
    components: { chooseCard },
    data() {
        return {
            subscriptions: [],
            recommendProducts: [],
            popupText: "",
            totalCount: 0,
            currentPage: 1,
            downloadedList:
                JSON.parse(sessionStorage.getItem("downloadedList")) || [],
            isTrial: false,
            storeUrl: "",
            isRecover: false,
            isChoosePayment: false,
            currentCard: null,
            accountStatus: {
                1: this.$t("subscription.active"),
                2: this.$t("subscription.pause"),
            },
            requestId: null,
            requestCurrency: null,
            isLifelong: null,
            currentSource: "",
            isRecommend: false,
        };
    },
    computed: {
        ...mapGetters(["isPro", "deviceName", "accountInfo", "vendorInfo"]),
        isAdmin() {
            return this.$route.meta.isAdmin;
        },
    },
    methods: {
        toManage(e, s, flag) {
            if (flag) return;
            this.$store.commit("subscription/toManage", {
                ...s,
                email: this.accountInfo.email,
            });
            this.$navigateTo(e, `/manage-subscription/${s.order_products_id}`);
        },
        leaveSubscription() {
            this.$parent.isWarning = true;
            this.$parent.popupText = this.$t("subscription.leave_tip");
        },
        handleConfirm() {
            this.$parent.isSuccess = true;
            this.$parent.isWarning = false;
            this.$parent.popupText = this.$t("subscription.leave_success");
        },
        handleChangePayment(e, id, c, bool) {
            // 增加卡号选择
            this.isChoosePayment = true;
            this.currentSource = "change-payment";
            this.requestId = id;
            this.isLifelong = bool;
            this.requestCurrency = c;
        },
        formatDate(value) {
            const dt = new Date(value * 1000);
            const year = dt.getFullYear();
            const month = ("0" + (dt.getMonth() + 1)).slice(-2);
            const day = ("0" + dt.getDate()).slice(-2);
            const hours = ("0" + dt.getHours()).slice(-2);
            const minutes = ("0" + dt.getMinutes()).slice(-2);
            const seconds = ("0" + dt.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        getInfo(email = "") {
            if (this.isAdmin && email == "") return;
            const fun = this.isAdmin ? querySubscription : getSubscription;
            const parameter = this.isAdmin ? email : this.currentPage;
            this.$parent.isRequest = true;
            fun(parameter)
                .then((res) => {
                    this.isTrial = res.data.is_trial == 1;
                    this.storeUrl = res.data.store_url;
                    this.subscriptions = res.data.list;
                    this.isRecommend =
                        this.isTrial || this.subscriptions.length == 0;
                    if (
                        this.isRecommend &&
                        this.recommendProducts.length == 0
                    ) {
                        this.getRecommendInfo();
                        return;
                    }
                    this.totalCount = res.data.total;
                })
                .catch((err) => {
                    this.isAdmin
                        ? (this.$parent.msg = err.response
                              ? err.response.data.message
                              : err.message)
                        : "";
                })
                .finally(() => {
                    this.$parent.isRequest = false;
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getInfo();
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        // hover模块直接下载软件
        // downloadProduct(i) {
        //     if (this.downloadedList.includes(i)) return;
        //     this.$refs[`downloadLink${i}`][0].click();
        //     this.downloadedList.push(i);
        //     sessionStorage.setItem(
        //         "downloadedList",
        //         JSON.stringify(this.downloadedList)
        //     );
        // },
        getRecommendInfo() {
            getRecommendation()
                .then((res) => {
                    this.recommendProducts = res.data.list;
                    this.$parent.isRequest = false;
                })
                .catch(() => {});
        },
        renewSub(id, state, c) {
            this.requestId = id;
            // 过期了，选择支付方式
            if (state == 1) {
                this.currentSource = "resume";
                this.isChoosePayment = true;
                this.requestCurrency = c;
                return;
            }
            this.recoverSub({ currency: c });
        },
        // 过期的订阅恢复,选择卡号之后的操作
        confirmPayWay() {
            if (this.currentSource == "resume") {
                this.recoverSub({
                    card_id: this.currentCard,
                    currency: this.requestCurrency,
                });
            } else if (this.currentSource == "change-payment") {
                this.comfirmChangePayment();
            }
        },
        judgeState(event, s) {
            /**
             * status 0未订阅,1订阅成功,2取消订阅,3订阅逾期,4,订阅暂停,5订阅过期
             * active_status=1 活跃 active / 2 暂停 pause / 3 逾期 Overdue(status=2||3)
             * pay_type = 1 外部订单
             * can_manage 1 正常可管理， 2 按钮置灰，显示处理中 ，  3 不可管理，直接隐藏
             */
            switch (event) {
                case "notSubscribed":
                    return s.status == 0;
                case "isStop":
                    return s.status == 4;
                case "overdue":
                    return s.active_status == 3;
                case "expired":
                    return s.status == 5;
                case "normal":
                    return s.status != 4 && s.status != 5 && s.status != 2 && s.can_manage !=3;
                case "stopOrCancel":
                    return s.status == 4 || s.status == 2;
            }
        },
        viewDetail(id, name, s_id) {
            this.$parent.manageSeletedInfo.softwareId = this.vendorInfo
                .planSearch
                ? ""
                : s_id;
            this.$parent.manageSeletedInfo.softwareName = name;
            this.$parent.manageSeletedInfo.subId = id;
            this.$parent.currentModule = 2;
        },
        recoverSub(other) {
            this.isRecover = true;
            operatSub(this.requestId, "recover", other)
                .then(() => {
                    this.isRecover = false;
                    this.$parent.isSuccess = true;
                    this.$parent.popupText = this.$t(
                        "subscription.renew_sucess"
                    );
                    if (
                        this.requestId ==
                        sessionStorage.getItem("isStopSuccess")
                    ) {
                        sessionStorage.removeItem("isStopSuccess");
                    }
                    this.getInfo();
                })
                .catch((err) => {
                    this.isRecover = false;
                    this.$parent.isError = true;
                    this.$parent.popupText = this.isPro
                        ? this.$t("common.error")
                        : err.response
                        ? err.response.data.message
                        : err.message;
                });
        },
        comfirmChangePayment() {
            const that = this;
            changePayment(
                this.requestId,
                this.requestCurrency,
                this.currentCard
            ).then((res) => {
                const cardsComponent = that.$refs.cardsComponent;
                if (cardsComponent.payMethod == 1) {
                    cardsComponent.googlePay(res.data, this.isLifelong);
                    return;
                }
                cardsComponent.isLoading = false;
                this.successPayment();
            });
        },
        successPayment() {
            this.isChoosePayment = false;
            this.$parent.isSuccess = true;
            this.$parent.popupText = this.$t(
                "subscription.update_payment_success"
            );
            this.getInfo();
        },
    },
    created() {
        this.getInfo();
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/personal.scss";

a {
    text-decoration: none;
}
</style>
