<template>
    <div
        class="personal-subscription"
        style="
            background: #f2f2f2;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            position: relative;
        "
    >
        <table
            role="presentation"
            cellpadding="0"
            cellspacing="0"
            border="0"
            align="center"
            style="
                width: 100%;
                border: 0;
                padding: 60px 40px;
                min-height: 100%;
                height: 100%;
                background: #fff;
                max-width: 800px;
                margin: 0 auto;
            "
            width="100%"
            height="100vh"
            v-if="bilingInfo"
        >
            <tbody
                style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                "
            >
                <tr style="padding: 0 30px 30px 30px; height: 111px">
                    <td
                        style="
                            vertical-align: middle;
                            padding: 0 0 30px;
                            border-bottom: 1px solid #e6e6e6;
                        "
                    >
                        <div style="display: flex; align-items: center">
                            <img
                                loading="lazy"
                                style="
                                    width: 80px;
                                    height: 80px;
                                    padding-right: 20px;
                                "
                                :src="bilingInfo.logo"
                                alt="logo"
                            />
                            <p style="font-size: 25px; font-weight: 600">
                                {{ bilingInfo.brand }}
                            </p>
                        </div>
                        <div
                            style="
                                margin: 0;
                                padding: 0;
                                vertical-align: top;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 27px;
                                color: #000000;
                            "
                        >
                            <p style="display: block; margin: 0; padding: 0">
                                {{
                                    $t("receipt.title", {
                                        name: bilingInfo.brand,
                                    })
                                }}
                            </p>

                            <p
                                style="
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: #000000;
                                "
                            >
                                {{ $t("receipt.receipt_text") }} #
                                <span>{{ bilingInfo.order_no }}</span>
                            </p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        style="
                            padding: 30px 0;
                            border-bottom: 1px solid #e6e6e6;
                        "
                    >
                        <p
                            style="
                                padding: 0;
                                margin: 0;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <span
                                style="
                                    display: inline-flex;
                                    flex-direction: column;
                                "
                                >{{ $t("receipt.amount_paid") }}:
                                <span style="display: block; font-weight: 700"
                                    >{{ bilingInfo.final_price }}
                                    {{ bilingInfo.currency }}</span
                                ></span
                            >
                            <span
                                style="
                                    display: inline-flex;
                                    flex-direction: column;
                                "
                                >{{ $t("receipt.date") }}:
                                <span
                                    style="display: block; font-weight: 700"
                                    >{{ bilingInfo.created_at }}</span
                                ></span
                            >
                            <span
                                style="
                                    display: inline-flex;
                                    flex-direction: column;
                                "
                                >{{ $t("receipt.pay_method") }}:
                                <span style="display: flex; font-weight: 700">
                                    <img
                                        loading="lazy"
                                        v-if="bilingInfo.final_price != 0"
                                        :src="bilingInfo.bank_icon"
                                        alt="card icon"
                                    />
                                    &nbsp;
                                    {{
                                        bilingInfo.final_price == 0
                                            ? "-"
                                            : bilingInfo.card_num
                                            ? bilingInfo.card_num
                                            : ""
                                    }}</span
                                ></span
                            >
                        </p>
                    </td>
                </tr>
                <tr>
                    <table class="product-list">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td style="width: 240px">
                                    {{ $t("receipt.item") }}
                                </td>
                                <td>{{ $t("receipt.qty") }}</td>
                                <td>{{ $t("receipt.price") }}</td>
                                <td>{{ $t("receipt.amount") }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(p, index) in bilingInfo.products"
                                :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ p.plan_name }}</td>
                                <td>{{ p.quantity }}</td>
                                <td>
                                    {{ p.original_price
                                    }}{{ bilingInfo.currency }}
                                </td>
                                <td class="w-600">
                                    {{ p.final_price }}{{ bilingInfo.currency }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td>{{ $t("receipt.subtotal") }}</td>
                                <th>
                                    {{ bilingInfo.final_price
                                    }}{{ bilingInfo.currency }}
                                </th>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <th>{{ $t("receipt.total") }}</th>
                                <th>
                                    {{ bilingInfo.final_price
                                    }}{{ bilingInfo.currency }}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </tr>
                <tr>
                    <td
                        style="padding: 30px 0 0; border-top: 1px solid #e6e6e6"
                    >
                        <p
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                            "
                        >
                            {{
                                $t("receipt.contact_tip", {
                                    brand: bilingInfo.brand,
                                })
                            }}:
                            <br />
                            <a
                                style="color: #0540ff"
                                :href="`mailto:support@${vendorInfo.vendor}.com`"
                                >support@{{ vendorInfo.vendor }}.com</a
                            >
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="padding: 30px 0px 0">
                        <p
                            style="
                                padding: 0;
                                margin: 0;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                            "
                        >
                            {{ $t("receipt.thank") }}
                        </p>
                        <p
                            style="
                                padding: 0;
                                margin: 0;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                            "
                        >
                            {{ bilingInfo.brand }}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getOrderDetail } from "@/api/common.js";

export default {
    name: "receiptPage",
    data() {
        return {
            bilingInfo: null,
        };
    },
    computed: {
        ...mapGetters(["isPro", "vendorInfo"]),
    },
    methods: {},
    mounted() {
        getOrderDetail(
            sessionStorage.getItem("order_id"),
            sessionStorage.getItem("int_id")
        )
            .then((res) => {
                this.bilingInfo = res.data;
            })
            .catch(() => {
                this.$navigateTo({ text: "账号未登录" }, "/login");
            });
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.back_link {
    color: #0540ff;
    text-decoration: underline;
    position: absolute;
    right: 100px;
    top: 50px;
    cursor: pointer;
}
.product-list {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    thead td {
        border-top: none;
        text-align: left;
    }
    td {
        padding: 15px 10px;
        text-align: left;
        border: solid #e6e6e6;
        border-width: 1px 0;
        color: #000;

        &:empty {
            border: none;
        }
        &:first-of-type {
            padding-left: 0;
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
            text-align: center;
        }
        &:last-of-type {
            padding-right: 0;
            text-align: right;
        }
    }
    tbody tr {
        &:last-of-type,
        &:nth-last-of-type(2) {
            th {
                font-weight: 700;
            }
            td,
            th {
                border-bottom: none;
                text-align: center;
            }
            th:last-of-type {
                text-align: right;
            }
        }
    }
    .w-600 {
        font-weight: 600;
    }
}
</style>
