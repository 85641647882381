<template>
    <div class="reset-pwd">
        <p class="title">
            {{
                hadSend
                    ? $t("dm_reset_pwd.reset_pwd")
                    : $t("dm_reset_pwd.reset")
            }}
        </p>
        <p v-if="!hadSend" class="text">
            {{ $t("dm_reset_pwd.reset_tip") }}
        </p>
        <custom-form
            ref="form"
            :formInfo="resetInfo"
            :bottomLink="{
                href: '/login',
                text: $t('dm_reset_pwd.back_login'),
            }"
            :confirmText="
                !hadSend ? $t('register.recover_pwd') : $t('register.reset')
            "
        >
        </custom-form>
    </div>
</template>

<script>
import customForm from "../customForm.vue";
import { sendResetPwdEamil, resetPwd } from "@/api/login.js";

export default {
    name: "resetComponent",
    components: { customForm },
    data() {
        return {
            resetInfo: {},
            form: null,
            hadSend: null,
            checked: false,
        };
    },
    methods: {
        handleConfirm() {
            const info = this.form.getReqInfo();
            if (!this.hadSend) {
                sendResetPwdEamil(info)
                    .then(() => {
                        this.form.errorTip = "";
                        this.form.successTip = this.$t(
                            "dm_reset_pwd.email_had_sent"
                        );
                        this.form.isLoading = false;
                    })
                    .catch((err) => {
                        this.form.successTip = "";
                        this.form.isLoading = false;
                        const res = err.response ? err.response.data : err;
                        if (res.status == 433) {
                            this.form.errorTip = this.$t(
                                "dm_reset_pwd.no_sign_up"
                            );
                        } else {
                            this.form.errorTip = res.message;
                        }
                        this.form.judegeCaptcha(res.message);
                    });
            } else {
                resetPwd(
                    info.password,
                    info.password_confirmation,
                    this.$route.params.other
                )
                    .then(() => {
                        this.form.isLoading = false;
                        this.$parent.isSuccess = true;
                        this.$parent.popupText = this.$t(
                            "dm_reset_pwd.reset_success"
                        );
                        this.$navigateTo({ text: "重置密码成功" }, "/login");
                    })
                    .catch((err) => {
                        this.form.isLoading = false;
                        this.$parent.isError = true;
                        this.$parent.popupText = err.response
                            ? err.response.data.message
                            : err.message;
                    });
            }
        },
        opCaptcha(n) {
            n == 0
                ? this.$set(this.resetInfo, "_captcha", {
                      type: "captcha",
                      value: "",
                  })
                : delete this.resetInfo._captcha;
        },
    },
    mounted() {
        this.hadSend = this.$route.meta.hadSend;
        if (this.hadSend) {
            this.$set(this.resetInfo, "password", {
                type: "password",
                value: "",
                customText: this.$t("userInfoModule.updatePwd.new_pwd_p"),
            });
            this.$set(this.resetInfo, "password_confirmation", {
                type: "password",
                value: "",
                customText: this.$t("userInfoModule.updatePwd.confirm_pwd_p"),
            });
        } else {
            this.$set(this.resetInfo, "email", {
                type: "email",
                value: "",
            });
        }
        this.form = this.$refs.form;
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/dm/loginModule.scss";
</style>
