import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import getVendor from "@/config/vendorConfig.js";

import { Button, Form, Carousel, CarouselItem, Pagination, FormItem, Input, Checkbox, Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./language";

import loginLeft from "@/components/loginLeft.vue";

// import "@/assets/scss/select.scss";
import { onClickPage } from "@/utils/parse.js";

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Form);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Loading);
Vue.component("login-left", loginLeft);

// 自定义跳转方法
Vue.prototype.$navigateTo = function (e, destination) {
    router.push(destination);
    onClickPage(e, destination);
};

// window.reqUrl = process.env.VUE_APP_HOST;
window.reqUrl = location.origin;

async function init() {
    let vendorInfo = await getVendor();
    if (vendorInfo) {
        window.analyticInterface = vendorInfo.analyticInterface;
        window.vendorName = vendorInfo.name;
        sessionStorage.setItem("vendor", vendorInfo.vendor);
        document.title = `${vendorInfo.name} Dashboard`;
        let $favicon = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
            $favicon.href = vendorInfo.logo;
        } else {
            $favicon = document.createElement("link");
            $favicon.rel = "icon";
            $favicon.href = vendorInfo.logo;
            document.head.appendChild($favicon);
        }

        store.commit("common/saveVendorInfo", vendorInfo);
        new Vue({
            i18n,
            router,
            store,
            render: h => h(App)
        }).$mount("#app");
    }
}
init();
