<template>
    <div class="login-right">
        <p class="title">{{ $t("register.new_account") }}</p>
        <p class="login-tip">{{ $t("register.reg_tip") }}</p>
        <el-form
            ref="regForm"
            :rules="regRules"
            :model="regInfo"
            @submit.native.prevent
            @validate="handleValidation"
        >
            <el-form-item prop="username">
                <el-input
                    class="input-user"
                    v-model="regInfo.username"
                    :placeholder="$t('common.username')"
                >
                    <p slot="suffix" class="name-count">
                        {{ regInfo.username.length }}/50
                    </p>
                </el-input>
            </el-form-item>
            <el-form-item prop="email">
                <el-input
                    class="input-email required"
                    v-model="regInfo.email"
                    :placeholder="$t('common.email')"
                ></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    class="input-pwd required"
                    v-model="regInfo.password"
                    :type="showPwd0 ? 'text' : 'password'"
                    :placeholder="$t('common.pwd')"
                    autocomplete="off"
                >
                    <eye
                        slot="suffix"
                        :isText="showPwd0"
                        :isvh="true"
                        @changeStatus="showPwd0 = !showPwd0"
                    ></eye>
                </el-input>
            </el-form-item>
            <el-form-item prop="password_confirmation">
                <el-input
                    class="input-pwd required"
                    v-model="regInfo.password_confirmation"
                    :type="showPwd1 ? 'text' : 'password'"
                    :placeholder="$t('register.confirm_pwd')"
                    autocomplete="off"
                >
                    <eye
                        slot="suffix"
                        :isText="showPwd1"
                        :isvh="true"
                        @changeStatus="showPwd1 = !showPwd1"
                    ></eye>
                </el-input>
            </el-form-item>
            <el-form-item class="mb0">
                <el-button
                    class="active"
                    @click="handleRegister"
                    native-type="sumbit"
                    v-loading="isLoading"
                    >{{
                        isLoading ? "" : $t("register.new_account")
                    }}</el-button
                >
            </el-form-item>
            <el-form-item class="mb0">
                <p
                    class="consent"
                    v-html="
                        $t('register.terms_and_policy', {
                            term: $t('register.term'),
                            policy: $t('register.policy'),
                        })
                    "
                ></p>
            </el-form-item>
            <el-form-item class="mb0">
                <p class="to-login" @click="changePage('login')">
                    {{ $t("register.have_account") }}
                    <a class="p10">{{ $t("register.login") }}</a>
                </p>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { register } from "@/api/login.js";
import { showMessage } from "@/utils/message.js";
import eye from "@/components/eye.vue";
import {
    validateUsername,
    validateEmail,
    validatePassword,
    isJSONString,
} from "@/utils/formValidation.js";
import { setCookie } from "@/utils/operateCookie.js";

export default {
    name: "registerPage",
    components: { eye },
    data() {
        return {
            regInfo: {
                username: "",
                email: "",
                password: "",
                password_confirmation: "",
                brand: "videohunter",
            },
            regRules: {
                username: [
                    {
                        validator: validateUsername,
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        validator: validateEmail,
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        validator: validatePassword,
                        trigger: "blur",
                    },
                ],
                password_confirmation: [
                    { validator: this.contrastPwd, trigger: "blur" },
                ],
            },
            showPwd0: false,
            showPwd1: false,
            isLoading: false,
        };
    },
    computed: {},
    methods: {
        contrastPwd(rule, value, callback) {
            if (this.regInfo.password === value) {
                callback();
            } else {
                callback(new Error(this.$t("register.pwd_different")));
            }
        },
        handleRegister() {
            // 用户名非必填，后端校验必填
            this.$refs["regForm"].validate(async (res) => {
                if (!res) {
                    showMessage(this.$t("common.format_error"), "error");
                } else {
                    this.isLoading = true;
                    register(this.regInfo)
                        .then((response) => {
                            const d = response.data;
                            showMessage(
                                this.$t("register.reg_success"),
                                "success"
                            );
                            sessionStorage.setItem("vhHasLogin", true);
                            setCookie("_token", d.access_token, d.expires_at);
                            // 给iframe外传
                            const data = {
                                identifier: "loginSuccess",
                                success: true,
                                info: d,
                                email: this.regInfo.email,
                            };
                            window.parent.postMessage(data,process.env.VUE_APP_PARENT);
                            this.isLoading = false;
                            this.changePage("personal");
                        })
                        .catch((error) => {
                            let errMsg;
                            if (error.response) {
                                errMsg =
                                    error.response.status === 500
                                        ? this.$t("register.reg_fail_server")
                                        : error.response.data.message;
                            } else {
                                errMsg =
                                    error.message == "Network Error"
                                        ? this.$t("register.reg_fail_net")
                                        : error.message;
                            }
                            showMessage(errMsg, "error");
                            this.isLoading = false;
                        });
                }
            });
        },
        changePage(name) {
            this.$parent.changePage(name);
        },
        handleValidation(prop, isValid, errorMessage) {
            if (!isValid) {
                const filteredChildren = this.$refs.regForm.$children.filter(
                    (item) => item.prop === prop
                );
                filteredChildren[0].validateMessage = isJSONString(errorMessage)
                    ? this.$t(JSON.parse(errorMessage).t)
                    : errorMessage;
            }
        },
    },
    mounted() {
        const term = document.querySelector(".term");
        const policy = document.querySelector(".policy");
        // const that = this;
        term.addEventListener("click", () => {
            // that.changePage("/条款路径")
        });
        policy.addEventListener("click", () => {
            // that.changePage("/政策路径")
        });
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/login.scss";

.to-login {
    margin-top: 36px;
}
.consent {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
    a {
        padding: 10px 0;
    }
}
::v-deep {
    .term,
    .policy {
        cursor: pointer;
    }
}
.name-count {
    position: relative;
    left: -35px;
    color: #87898c;
    font-size: 14px;
    line-height: 40px;
}
.is-error .name-count {
    color: #ff0700;
}
@media screen and (max-width: 950px) {
    .name-count {
        left: 0;
    }
}
</style>
