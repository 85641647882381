<template>
    <div class="login-right">
        <p class="title">{{ $t("login.title") }}</p>
        <p class="login-tip">{{ $t("login.login_tip") }}</p>
        <el-form
            ref="loginForm"
            :rules="loginRules"
            :model="loginInfo"
            @submit.native.prevent
            @validate="handleValidation"
        >
            <el-form-item prop="email">
                <el-input
                    class="input-email"
                    v-model="loginInfo.email"
                    :placeholder="$t('common.email')"
                ></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    class="input-pwd"
                    v-model="loginInfo.password"
                    :type="showPwd ? 'text' : 'password'"
                    :placeholder="$t('common.pwd')"
                    autocomplete="off"
                    @input="changwPwd"
                >
                    <eye
                        slot="suffix"
                        :isText="showPwd"
                        :isvh="true"
                        @changeStatus="changePwdStatus"
                        :class="unchangeable ? 'disabled-change' : ''"
                    ></eye>
                </el-input>
            </el-form-item>
            <el-form-item prop="_captcha" v-if="needCaptcha">
                <el-input
                    class="input-captcha"
                    v-model="loginInfo._captcha"
                    :placeholder="'请输入验证码'"
                >
                    <img
                        loading="lazy"
                        v-if="!isRefresh"
                        @click="refreshCaptcha"
                        slot="suffix"
                        :src="captchaUrl"
                        width="120px"
                        height="40px"
                        alt="captcha"
                    />
                </el-input>
            </el-form-item>
            <el-form-item class="mb0">
                <div class="pw-operate">
                    <el-checkbox
                        el-checkbox
                        v-model="checked"
                        :label="$t('login.save_pwd')"
                    ></el-checkbox>
                    <a class="p10" @click="changePage('forget-password')">{{
                        $t("login.forgot_pwd")
                    }}</a>
                </div>
            </el-form-item>
            <el-form-item class="mb0">
                <el-button
                    class="active"
                    @click="handleLogin"
                    native-type="submit"
                    v-loading="isLoading"
                    >{{ isLoading ? "" : $t("login.login") }}</el-button
                >
            </el-form-item>
            <el-form-item class="mb0">
                <p class="to-register" @click="changePage('register')">
                    {{ $t("login.no_account") }}
                    <a class="p10">{{ $t("login.create_account") }}</a>
                </p>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { login, refreshToken } from "@/api/login.js";
import { showMessage } from "@/utils/message.js";
import eye from "@/components/eye.vue";
import {
    validateEmail,
    validateLoginPwd,
    validateCaptcha,
    isJSONString,
} from "@/utils/formValidation.js";
import { setCookie } from "@/utils/operateCookie.js";

export default {
    name: "loginPage",
    components: { eye },
    data() {
        return {
            loginInfo: {
                email: "",
                password: "",
                _captcha: "",
                brand: "videohunter",
            },
            loginRules: {
                email: [{ validator: validateEmail, trigger: "blur" }],
                password: [
                    {
                        validator: validateLoginPwd,
                        trigger: "blur",
                    },
                ],
                _captcha: [
                    {
                        validator: validateCaptcha,
                        trigger: "blur",
                    },
                ],
            },
            showPwd: false,
            checked: false,
            isLoading: false,
            needCaptcha: false,
            count: 0,
            isRefresh: false,
            unchangeable: false,
            autoFillPwd: false,
        };
    },
    computed: {
        captchaUrl() {
            return `${window.reqUrl}/api/user/captcha`;
        },
    },
    methods: {
        handleLogin() {
            if (this.autoFillPwd) {
                this.isLoading = true;
                refreshToken()
                    .then((res) => {
                        this.isLoading = false;
                        this.isLoginSuccess(res.data);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        let errTip = this.isPro
                            ? this.$t("common.error")
                            : err.response
                            ? err.response.data.message
                            : err.message;
                        if (
                            err.response &&
                            err.response.data.message ==
                                "Login expired, please log in again!"
                        ) {
                            errTip = this.$t("dm_login.login_expired");
                            this.loginInfo.password = "";
                            localStorage.removeItem("token");
                        }
                        showMessage(errTip, "error");
                    });
                return;
            }
            this.$refs["loginForm"].validate(async (res) => {
                if (!res) {
                    showMessage(this.$t("common.format_error"), "error");
                } else {
                    this.isLoading = true;
                    login(this.loginInfo)
                        .then((response) => {
                            if (this.checked) {
                                localStorage.setItem(
                                    "token",
                                    response.data.access_token
                                );
                                localStorage.setItem(
                                    "account",
                                    this.loginInfo.email.trim()
                                );
                            }
                            this.isLoginSuccess(response.data);
                        })
                        .catch((err) => {
                            // this.$t("login.pwd_error")
                            this.isLoading = false;
                            this.count++;
                            if (this.count >= 3) {
                                this.needCaptcha = true;
                            }
                            let errMsg = err.response
                                ? err.response.data.message
                                : err.message;
                            if (errMsg == "CaptchaNotCorrect!") {
                                this.needCaptcha = true;
                                return;
                            }
                            showMessage(errMsg, "error");
                        });
                }
            });
        },
        isLoginSuccess(d) {
            showMessage(this.$t("login.login_success"), "success");
            sessionStorage.setItem("vhHasLogin", true);
            this.$store.commit("user/setEmail", this.loginInfo.email.trim());
            // 给iframe外传
            const data = {
                identifier: "loginSuccess",
                success: true,
            };
            window.parent.postMessage(data, process.env.VUE_APP_PARENT);
            this.isLoading = false;
            setCookie("_token", d.access_token, d.expires_at);
        },
        changePage(name) {
            this.$parent.changePage(name);
        },
        handleValidation(prop, isValid, errorMessage) {
            if (!isValid) {
                const filteredChildren = this.$refs.loginForm.$children.filter(
                    (item) => item.prop === prop
                );
                filteredChildren[0].validateMessage = isJSONString(errorMessage)
                    ? this.$t(JSON.parse(errorMessage).t)
                    : errorMessage;
            }
        },
        refreshCaptcha() {
            this.isRefresh = true;
            let timer = setTimeout(() => {
                this.isRefresh = false;
                clearTimeout(timer);
            });
        },
        changePwdStatus() {
            if (this.unchangeable) return;
            this.showPwd = !this.showPwd;
        },
        changwPwd(event) {
            if (this.autoFillPwd && event != "***********") {
                this.autoFillPwd = false;
            }
            if (event.length <= 1 && this.unchangeable) {
                this.unchangeable = false;
            }
        },
    },
    mounted() {
        if (localStorage.getItem("token") && localStorage.getItem("account")) {
            this.loginInfo.email = localStorage.getItem("account");
            this.loginInfo.password = "***********";
            this.showPwd = false;
            this.autoFillPwd = true;
            this.unchangeable = true;
            this.checked = true;
        }
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/login.scss";

$blue: #3b7fff;
.to-register {
    margin-top: 20px;
}
::v-deep {
    .el-checkbox {
        height: 20px;
        display: flex;
        @include rowGap(4px);
        align-items: center;
        .el-checkbox__label {
            padding: 0;
            color: #000;
        }
        .el-checkbox__input {
            .el-checkbox__inner {
                width: 16px;
                height: 16px;
                margin-top: 2px;
                border-color: #d4d6d9;
                &::after {
                    left: 5px;
                    top: 2px;
                }
            }
            &.is-checked {
                .el-checkbox__inner {
                    background: $blue;
                    border-color: $blue;
                }
                & + .el-checkbox__label {
                    color: #000 !important;
                }
            }
            &.is-focus {
                .el-checkbox__inner {
                    border-color: $blue;
                }
            }
        }
    }
    .el-button {
        margin-top: 35px !important;
    }
}
@media (hover: hover) {
    .el-checkbox__input:hover {
        .el-checkbox__inner {
            border-color: $blue;
        }
    }
}
</style>
