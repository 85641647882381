export default {
    choose_lang: "日本語",
    common: {
        email: "Eメール",
        pwd: "パスワード",
        send: "送信",
        format_error: "格式错误！",
        error: "エラー ",
        now: "(今)",
        vip_system: "メンバーシステム",
        first_name: "名前",
        last_name: "姓",
        tip_first_name: "名前",
        tip_last_name: "姓",
    },
    login: {
        login: "ログイン",
        title: "お帰りなさい",
        login_tip: "アカウントにログインしてください",
        save_pwd: "パスワードを記憶する",
        free_login: "15日以内の自動ログイン",
        forgot_pwd: "パスワードを忘れた場合",
        no_account: "アカウントをお持ちではありませんか？",
        create_account: "今すぐ作成",
        email_format_error: "Eメールのフォーマットが正しくありません",
        pwd_error: "パスワードが正しくありません",
        account_no_exist: "このアカウントは存在しません",
        login_success: "ログインに成功しました",
    },
    register: {
        new_account: "新しいアカウントを作成する",
        reg_tip: "以下の情報を入力して、新しいアカウントを作成してください。",
        confirm_pwd: "パスワードを確認する",
        reg_account: "アカウントを作成する",
        terms_and_policy:
            "アカウントを作成すると、<a class='term'>{term}</a>および<a class='policy'>{policy}</a>に同意したことになります。",
        term: "利用規約",
        policy: "プライバシーポリシー",
        have_account: "アカウントをお持ちの方、",
        login: "すぐログイン",
        name_limit: "{fieldName}は{max}文字を超えてはいけません！",
        email_invalid: "無効なメールアドレス",
        email_no_exist: "メールアドレスが存在しません",
        pwd_limit:
            "パスワードは8～20文字で、特殊記号なしで、数字1つと英字1つ（大文字小文字は区別しない）を含む必要があります。",
        pwd_different: "入力されたパスワードは一致しません！",
        reg_fail_server:
            "作成に失敗しました！ エラーが修復中、後で再試行してください。",
        reg_fail_net:
            "作成に失敗しました！ エラーが修復中、後で再試行してください。",
        reg_success: "アカウントの作成に成功しました",
        check_tip: "私は利用規約に同意します。",
        please_accept: "利用規約に同意するにはチェックしてください。",
        recover_pwd: "パスワードを再設定する ",
        reset: "リセット ",
        account_presence:
            "このメールアドレスはすでに存在します。ログインしてください！",
        receive_newsletter: "購読して当社のニュースレターを受け取る。",
    },
    forget: {
        title: "パスワードを復元します",
        tip: "パスワードリセットのメールをお送りしますので、Eメールアドレスを入力してください。",
        backToLogin: "<a class='loginLink'>{login}</a>に戻る",
        login: "ログイン",
        forget_success: "パスワードリセットのメールを送信いたしました。",
        send_fail_server:
            "送信に失敗しました！エラーが修復中、後で再試行してください。",
        send_fail_net:
            "送信に失敗しました！ ネットワーク接続状況をチェックして、再試行してください。",
    },
    update: {
        title: "パスワードを変更する",
        tip: "新しいパスワードを入力してください。",
        new_pwd: "新しいパスワード",
        submit: "確認",
        update_fail_server:
            "変更に失敗しました！エラーが修復中、後で再試行してください。",
        update_fail_net:
            "変更に失敗しました！ネットワーク接続状況をチェックして、再試行してください。",
        update_success: "変更に成功しました",
    },
    psersonal: {
        sidebarItems: [
            "個人情報",
            "購読",
            "デバイスの管理",
            "請求",
            "サポートに連絡",
            "付加価値サービス",
            "サブアカウントの機能",
            "パスワードの変更",
            "ショッピングカート",
        ],
    },
    userInfoModule: {
        edit: "編集",
        name: "名前",
        update: "更新",
        reg_time: "登録時間",
        updatePwd: {
            forgot: "忘れた",
            current_pwd: "現在のパスワード",
            current_pwd_p: "現在のパスワードを入力",
            new_pwd_p: "新しいパスワードを入力",
            confirm_pwd_p: "新しいパスワードを繰り返す",
            update_btn: "パスワードを更新",
        },
        update_success: "名前の変更が成功しました",
        pwd_update_success:
            "パスワードが正常に変更されました。再ログインしてください。",
        profile_update_success: "アバターの変更が成功しました",
        destroy: "アカウントからログアウト",
        destroy_tip: "アカウントを削除しますか?",
        destroy_tip2:
            "この操作により、アカウントと関連データが永久に削除されます。ログアウト成功後、アカウントで有効化された機能は利用できなくなりますので、注意して進めてください。",
        destroy_success: "アカウントは正常にキャンセルされました!",
        pwd_wrong: "ユーザーパスワードが間違っています！",
        have_sub:
            "正常な更新状態のサブスクリプションはキャンセルできません。まずサブスクリプションを一時停止してください！",
    },
    subscription: {
        stop_text:
            "{plan}のメンバーシップサブスクリプションプランを一時停止しました。",
        cancel_text: "{plan}の会員加入プランをキャンセルしました。",
        canceling:
            "あなたの{plan}メンバーシップサブスクリプションプランは現在キャンセル中です。",
        renew: "購読を再開する",
        renew_sucess: "購読の再開に成功しました！",
        expire_date: "有効期限",
        status_text: "サブスクリプションのステータス",
        leave: "退出",
        purchase_date: "購入時間",
        end_date: "次回の支払い",
        price: "価格",
        num: "サポートされるデバイスの数",
        account_status: "アカウントの状態",
        no_subscription: "現在、サブスクリプションはありません,",
        recommend: "以下のコンテンツに興味があるかもしれません",
        learn_more: "詳細を確認",
        free_download: "無料ダウンロード",
        trial_limit: "トライアル制限",
        blocked_on: "ブロックされた場所",
        trial_start: "トライアル開始時間",
        start_subscription: "サブスクリプションを開始",
        is_trial: "現在、トライアル状態です",
        manage_btn: "サブスクリプションの管理",
        change_payment: "お支払いの詳細を変更",
        payment_detail: "支払いの詳細",
        leave_tip: "ファミリープランを終了してもよろしいですか？",
        leave_success: "ファミリープランから正常に退出しました！",
        singular_device_num:
            "{type}デバイスが{num1}台利用可能で、{num2}台がアクティブ化されています",
        plural_device_num:
            "{type}デバイスが{num1}台利用可能で、{num2}台がアクティブ化されています",
        see_detail: "詳細を見る",
        active: "アクティブ",
        pause: "一時停止",
        overdue: "(期限切れ)",
        update_payment_success: "支払い方法が正常に変更されました",
        being_processed: "処理中",
    },
    subaccount: {
        invite: "人々を招待して参加させる",
        send_invite: "招待を送る",
        manage_title: "家族メンバーを管理",
        remove: "削除する",
        send_success:
            "招待メールを正常に送信しました。相手の確認を待っています！",
        remove_user: "ユーザー{name}を削除してもよろしいですか？",
        all_product: "すべての製品",
    },
    billing: {
        excl_tax: "（消費税別）",
        view_receipt: "レシートを表示",
        all_time: "すべての時間",
        refunded: "返金済",
    },
    manage_devices: {
        reset_all_code: "すべてのデバイスをリセットする",
        reset_a_code: "現在のデバイスをリセットする",
        all_reset_success: "すべてのデバイスをリセットしました！",
        a_reset_success: "{name}をリセットしました！",
        num_tip: "今年はリセットの機会が{num}回しか残っていません。",
        a_warning_text: "{name}をリセットしますか？",
        all_warning_text: "すべてのデバイスをリセットすることを確認しますか？",
        device_name: "装置名",
        system: "システム ",
        reg_time: "登録時間",
        using_device: "{num2}台のデバイスのうち{num1}台を使用中",
        all: "全て",
        residue_num: "残りのリセット試行回数",
        reset_history: "リセット履歴の時間",
        reset_fail_9: "リセット失敗、リセットするデータが見つかりません",
        reset_fail_10:
            "リセットに失敗しました。回数制限を超えています。ご質問がある場合は、サポートにお問い合わせください。",
    },
    contact: {
        support_center: "サポートセンター",
        support_tip: "ここで必要な情報を見つける",
        feedback: "フィードバック",
        submit: "送信する",
        feedback_fail:
            "フィードバックの送信に失敗しました。後で再試行してください！",
        feedback_success: "フィードバックありがとうございます！",
        enter_content: "フィードバック内容を入力してください",
        content_placeholder: "ご提案やご質問がありましたら、こちらにご記入ください。",
        select: "選択",
    },
    dm_home: {
        logout: "ログアウト",
    },
    dm_login: {
        login_desc: "お帰りなさい、アカウントにログインしてください。",
        captcha_pl: "バリデーションコードを入力してください",
        or: "または",
        reg: "登録",
        enter_tip: "{fieldName}を入力してください。",
        enter_email: "メールアドレスを入力してください",
        enter_pwd: "パスワードを入力してください",
        captcha_limit: "認証コードの長さは4です",
        login_expired:
            "アカウントのセキュリティを保護するため、パスワードを再入力する必要があります。",
        account_pwd_err: "ログインアカウントまたはパスワードが間違っています！",
    },
    dm_reg: {
        create: "アカウントを作成",
        fill_form:
            "新しいアカウントを作成するには、以下のフォームに記入してください。",
    },
    dm_reset_pwd: {
        reset: "パスワードを回復する",
        reset_pwd: "パスワードをリセットする",
        reset_tip:
            "メールアドレスを入力してください。パスワードのリセット方法についての手順をお送りします。",
        back_login: "ログインに戻る",
        email_error: "メールアドレスが存在しません",
        reset_success: "パスワードを正常にリセットしました。",
        email_had_sent:
            "パスワードリセットのメールがメールボックスに送信されました。パスワードをリセットしてください！",
        no_sign_up:
            "このメールアドレスにはまだアカウントがありません。アカウントをサインアップしてください。",
    },
    manageSubscription: {
        title: "サブスクリプションを管理",
        desc: "現在のプランは：",
        change_methods: "サブスクリプションの方法を変更",
        choose_system_tip: "必要なデバイスシステムを選択してください",
        choose_num_tip: "必要なデバイス数を選択してください",
        enter_num: "必要なデバイス数を入力してください",
        click_enter: "クリックして入力",
        confirm: "確認",
        cancel: "キャンセル",
        choose_card_tip: "支払いカード番号を選択してください",
        add_card: "カード番号を追加",
        choose_way_tip: "必要なサブスクリプション方法を選択してください",
        discounts_tip: "割引が適用されました",
        total: "合計：",
        excl_tax: "（消費税別）",
        be_counting: "価格を計算中",
        end_tip: "有効期限日：",
        thank_text:
            "ご継続いただき誠にありがとうございます。次回のご来店を心よりお待ちしております！",
        back: "サブスクリプションに戻る",
        update_success: "サブスクリプションの変更が成功しました",
        pay_btn: "今すぐ支払う",
        monthy: "月額",
        annual: "年間",
        life_time: "ライフタイム",
        more: "もっと",
        pay_tip:
            "支払方法の変更：プラットフォームは新しいカードから0.01を差し引き、差し引きが成功した場合、カードを連携できます。その後、金額は元の支払方法に返金されます。",
        is_add: "新しいカードを追加",
        is_set: "新しいカードを設定",
        cancel_sub: "購読を一時停止する",
        tip: "今すぐ購読を一時停止しても、{name}は現行の請求サイクル終了まで使用できます。",
        cancel_success: "購読の一時停止が成功しました。",
        cancel_text:
            "{productName}のメンバーシップサブスクリプションプランの一時停止が成功しました。ただし、サブスクリプションの最終日まで{productName}を引き続き利用できます。",
        are_you_sure: "購読を一時停止してもよろしいですか？",
        request_success: `ご要望は正常に送信されました。弊社のカスタマーサービスセンターが48時間以内に処理いたします。{officialEmail}からのメールをご確認ください。`,
    },
    receipt: {
        title: "{name} の領収書",
        back: "戻る",
        receipt_text: "領収書",
        amount_paid: "支払額",
        date: "領収書の日付",
        pay_method: "支払方法",
        discount: "割引",
        discount_tip: "記載されているアイテムは価格に含まれています",
        contact_tip:
            "{brand}に関するご質問があれば、お気軽にお問い合わせください",
        thank: "ありがとうございます",
        item: "アイテムと説明",
        qty: "数量",
        price: "価格",
        amount: "総引き落とし金額",
        subtotal: "小計",
        total: "合計",
    },
    order_complete: {
        title: "注文完了！",
        desc: "ご購入いただきありがとうございます！配送のためのメールがまもなくお客様のメールアドレスに送信されます。",
        order_id: "ご注文番号",
        price: "注文合計",
        name: "製品名",
        delivery: "配達",
        electronic: "電子",
        unit_price: "単価",
        quantity: "数量",
        final: "割引価格",
        detail: "注文の詳細",
        license_email: "ライセンスされた電子メール",
        license_tip: "ライセンスキーは配信メールに含まれます。",
        contact_text:
            "配信メールが届かない場合は、カスタマーサポートにお問い合わせください：",
    },
    popup: {
        ok: "Ok",
        cancel: "キャンセル",
        confirm: "確認",
        options: ["ソフトウェアの問題", "価格", "その他"],
    },
    add_new_card: {
        please_fill: "情報を完備してください",
    },
};
