<template>
    <div>
        <div class="control-container">
            <div id="cardNumber" ref="cardNumber"></div>
            <div id="expiry" ref="expiry"></div>
            <div id="cvc" ref="cvc"></div>
        </div>
        <p class="err-tip">{{ errContent }}</p>
    </div>
</template>

<script>
import { loadAirwallex } from "airwallex-payment-elements";
import { mapGetters } from "vuex";

export default {
    components: {},
    name: "changePaymentMethod",
    data() {
        return {
            isLoading: false,
            Airwallex: null,
            cardNumber: null,
            elementsCompleted: {
                cardNumber: false,
                expiry: false,
                cvc: false,
            },
            errContent: "",
        };
    },
    computed: { ...mapGetters(["isPro"]) },
    methods: {
        // 终身
        confirmPayment(response) {
            console.log("调用支付控件终身方法");
            const that = this;
            this.Airwallex.confirmPaymentIntent({
                intent_id: response.data.id,
                client_secret: response.data.client_secret,
                element: that.cardNumber,
            }).then(() => {
                alert("success");
                that.$parent.successPayment();
            });
        },
        // 订阅
        createConsent(res) {
            console.log("调用支付控件订阅方法");
            const that = this;
            that.Airwallex.createPaymentConsent({
                intent_id: res.data.id, // PaymentIntent id
                customer_id: res.data.customer_id, // Customer id
                client_secret: res.data.client_secret, // PaymentIntent client secret
                currency: res.currency, // currency
                element: that.cardNumber, // card element or cardNumber element depends on your integration
                next_triggered_by: "merchant", // 'merchant' for MIT and 'customer' for CIT
            }).then(() => {
                alert("success");
                that.$parent.successPayment();
            });
        },
        fun() {
            this.cardNumber = this.Airwallex.createElement("cardNumber");
            const expiry = this.Airwallex.createElement("expiry");
            const cvc = this.Airwallex.createElement("cvc");

            this.cardNumber.mount("cardNumber");
            expiry.mount("expiry");
            cvc.mount("cvc");

            const allId = ["cardNumber", "expiry", "cvc"];
            allId.forEach((name) => {
                const element = this.$refs[name];
                if (!element) return;
                element.addEventListener("onChange", this.onChange);
                element.addEventListener("onBlur", this.onBlur);
            });
        },
        onChange(event) {
            const { type, complete } = event.detail;
            this.elementsCompleted[type] = complete;
        },
        onBlur(event) {
            const { error, complete } = event.detail;
            this.errContent = complete
                ? ""
                : error.message || JSON.stringify(error);
        },
    },
    mounted() {
        loadAirwallex({
            env: this.isPro ? "prod" : "demo",
            origin: window.location.origin, // Set up your event target to receive the browser events message
        }).then((res) => {
            this.Airwallex = res;
            this.fun();
        });
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.control-container {
    background: #f2f2f2;
    padding: 25px;
    border-radius: 10px;
    @include columnGap(10px);
}

.err-tip {
    padding: 20px 0;
    font-size: 14px;
    color: #ea5455;
}

#cardNumber,
#expiry,
#cvc {
    padding: 12px 20px;
    border-radius: 5px;
    background-color: #fff;
}
</style>
