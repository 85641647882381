<template>
    <el-form
        ref="customForm"
        :rules="validateRules"
        :model="formInfo"
        @submit.native.prevent
        @validate="handleValidation"
    >
        <el-form-item v-for="(field, k) in formInfo" :key="k" :prop="k">
            <el-input
                v-if="field.type === 'password'"
                class="pwd-input"
                v-model="field.value"
                :type="
                    isLogin ? (showPassword[k] ? 'text' : 'password') : 'text'
                "
                :style="
                    isLogin
                        ? ''
                        : showPassword[k]
                        ? ''
                        : '-webkit-text-security: disc'
                "
                :placeholder="
                    field.customText
                        ? field.customText
                        : inputTypes[k].placeholder
                "
            >
                <eye
                    slot="suffix"
                    :isText="showPassword[k]"
                    @changeStatus="showPassword[k] = !showPassword[k]"
                ></eye>
            </el-input>
            <el-input
                v-else-if="field.type === 'captcha'"
                class="input-captcha"
                type="text"
                v-model="field.value"
                :placeholder="$t('dm_login.captcha_pl')"
            >
                <img
                    loading="lazy"
                    v-if="!isRefresh"
                    @click="refreshCaptcha"
                    slot="suffix"
                    :src="captchaUrl"
                    width="120px"
                    height="48px"
                    alt="captcha"
                />
            </el-input>
            <el-input
                v-else
                class="input-text"
                v-model="field.value"
                type="text"
                :placeholder="inputTypes[k].placeholder"
            ></el-input>
            <slot v-if="field.slotName" :name="field.slotName"></slot>
        </el-form-item>
        <slot name="beforeBtn"></slot>
        <el-form-item>
            <div class="btn-container">
                <el-button
                    class="p-btn usable"
                    native-type="submit"
                    v-loading="isLoading"
                    @click="submitForm"
                    >{{ isLoading ? "" : confirmText }}</el-button
                >
                <div>
                    <p class="success-tip">{{ successTip }}</p>
                    <p class="error-tip">{{ errorTip }}</p>
                </div>
                <slot name="beforeOr"></slot>
                <p class="or">
                    <span>{{ $t("dm_login.or") }}</span>
                </p>
                <p
                    class="p-btn black"
                    @click="$navigateTo($event, bottomLink.href)"
                >
                    {{ bottomLink.text }}
                </p>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import Eye from "./eye.vue";
import { mapGetters } from "vuex";
import {
    validateEmail,
    validateLoginPwd,
    validatePassword2,
    isJSONString,
    validateCaptcha,
} from "@/utils/formValidation.js";
import { getCaptcha } from "@/api/login.js";

export default {
    components: { Eye },
    props: {
        formInfo: {
            type: Object,
            required: true,
        },
        bottomLink: {
            type: Object,
        },
        confirmText: {
            type: String,
        },
        isLogin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            inputTypes: {
                email: {
                    placeholder: this.$t("common.email"),
                },
                password: {
                    placeholder: this.$t("common.pwd"),
                },
                first_name: {
                    placeholder: this.$t("common.first_name"),
                },
                last_name: {
                    placeholder: this.$t("common.last_name"),
                },
                password_confirmation: {
                    placeholder: this.$t("register.confirm_pwd"),
                },
            },
            showPassword: {
                password: false,
                password_confirmation: false,
            },
            validateRules: {
                first_name: [
                    {
                        validator: this.validateName,
                        max: 30,
                        text: this.$t("common.tip_first_name"),
                        upper_text: this.$t("common.first_name"),
                    },
                ],
                last_name: [
                    {
                        validator: this.validateName,
                        max: 20,
                        text: this.$t("common.tip_last_name"),
                        upper_text: this.$t("common.last_name"),
                    },
                ],
                email: [
                    {
                        validator: validateEmail,
                    },
                ],
                password: [
                    {
                        validator: this.isLogin
                            ? validateLoginPwd
                            : validatePassword2,
                    },
                ],
                password_confirmation: [
                    {
                        validator: this.contrastPwd,
                    },
                ],
                _captcha: [
                    {
                        validator: validateCaptcha,
                    },
                ],
            },
            allProps: [],
            errorTip: "",
            successTip: "",

            isRefresh: false,
            needCaptcha: false,
            captchaUrl: "",
            captchaKey: "",
        };
    },
    computed: {
        ...mapGetters(["vendorInfo"]),
    },
    methods: {
        contrastPwd(rule, { value }, callback) {
            if (this.formInfo.password.value === value) {
                callback();
            } else {
                callback(new Error(this.$t("register.pwd_different")));
            }
        },
        validateName(rule, { value }, callback) {
            value = value.trim();
            if (value === "") {
                callback(
                    new Error(
                        this.$t("dm_login.enter_tip", { fieldName: rule.text })
                    )
                );
            } else if (value.length > rule.max) {
                callback(
                    new Error(
                        this.$t("register.name_limit", {
                            fieldName:
                                this.$i18n.locale == "pl_PL"
                                    ? rule.upper_text
                                    : rule.text,
                            max: rule.max,
                        })
                    )
                );
            } else {
                callback();
            }
        },
        handleValidation(prop, isValid, errorMessage) {
            if (isValid) {
                this.allProps = this.allProps.filter((item) => item !== prop);
                if (this.allProps.length == 0) {
                    this.errorTip = "";
                } else {
                    this.$refs.customForm.validateField(this.allProps[0]);
                }
            } else {
                if (!this.allProps.includes(prop)) {
                    this.allProps.push(prop);
                }
                this.errorTip = isJSONString(errorMessage)
                    ? this.$t(JSON.parse(errorMessage).t)
                    : errorMessage;
            }
        },
        submitForm() {
            if (this.isLoading) return;
            if (
                typeof this.$parent.beforeValidate == "function" &&
                !this.$parent.beforeValidate()
            )
                return;
            this.$refs["customForm"].validate(async (res) => {
                if (res) {
                    this.errorTip = "";
                    this.isLoading = true;
                    this.$parent.handleConfirm();
                }
            });
        },
        getReqInfo() {
            const info = {};
            for (const key in this.formInfo) {
                info[key] = this.formInfo[key].value;
            }
            info.brand = this.vendorInfo.vendor;
            this.needCaptcha ? (info._captcha_key = this.captchaKey) : "";
            return info;
        },
        refreshCaptcha() {
            this.isRefresh = true;
            getCaptcha()
                .then((res) => {
                    this.captchaUrl = res.img;
                    this.captchaKey = res.key;
                    this.isRefresh = false;
                })
                .catch(() => {
                    this.isRefresh = false;
                });
        },
        judegeCaptcha(msg) {
            if (msg == "CaptchaNotCorrect!") {
                this.needCaptcha = true;
                this.refreshCaptcha();
                this.$parent.opCaptcha(0);
                this.errorTip = this.$t("dm_login.captcha_pl");
            } else {
                this.needCaptcha = false;
                this.$parent.opCaptcha(1);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dm/customForm.scss";
</style>
