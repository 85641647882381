export default {
    choose_lang: "Nederlands",

    common: {
        email: "Email",
        pwd: "Wachtwoord",
        send: "Send",
        format_error: "格式错误！",
        error: "Fout",
        now: "(nu)",
        vip_system: "Lidmaatschapssysteem",
        first_name: "Voornaam",
        last_name: "Achternaam",
        tip_first_name: "voornaam",
        tip_last_name: "achternaam",
    },
    login: {
        login: "Inloggen",
        title: "Welcome back",
        login_tip: "Please log in to your account",
        save_pwd: "Wachtwoord opslaan",
        free_login: "Automatisch inloggen binnen 15 dagen",
        forgot_pwd: "Wachtwoord vergeten",
        no_account: "Don't have an account?",
        create_account: "Sign Up",
        email_format_error: "The format of your email address is not correct.",
        pwd_error: "Incorrect password!",
        account_no_exist: "This account does not exist!",
        login_success: "Login successful",
    },
    register: {
        new_account: "Create new account",
        reg_tip: "Fill in this form to create a new account",
        confirm_pwd: "Bevestig wachtwoord",
        reg_account: "Create account",
        terms_and_policy:
            "By signing up, you accept and agree to our <a class='term'>{term}</a> and <a class='policy'>{policy}</a>.",
        term: "Terms of Use",
        policy: "Privacy Policy",
        have_account: "Already have an account? ",
        login: "Log in",
        name_limit: "De {fieldName} mag niet meer dan {max} tekens bevatten!",
        email_invalid: "Invalid Email",
        email_no_exist: "This email does not exist",
        pwd_limit:
            "Het wachtwoord moet 8-20 tekens lang zijn, zonder speciale tekens, en moet ten minste 1 cijfer en 1 letter bevatten (hoofdletters en kleine letters zijn niet van belang).",
        pwd_different: "Wachtwoorden komen niet overeen!",
        reg_fail_server:
            "Account creation failed! The server is down for maintenance. Please try again later.",
        reg_fail_net:
            "Account creation failed! Please check your internet connection and try again.",
        reg_success: "Account created successfully ",
        check_tip: "Ik accepteer de voorwaarden.",
        please_accept: "Vink aan om de voorwaarden te accepteren.",
        recover_pwd: "Wachtwoord herstellen",
        reset: "Resetten",
        account_presence: "Dit e-mailadres bestaat al, log alstublieft in!",
        receive_newsletter: "Abonneer en ontvang onze nieuwsbrief.",
    },
    forget: {
        title: "Reset your password",
        tip: "Enter your email address and we'll send password reset instructions.",
        backToLogin: "Return to<a class='loginLink'>{login}</a>",
        login: "log in",
        forget_success: "We've sent you an email to reset your password!",
        send_fail_server:
            "Failed to send! The server is down for maintenance. Please try again later.",
        send_fail_net:
            "Failed to send! Please check your internet connection and try again.",
    },
    update: {
        title: "Change your password",
        tip: "Please enter your new password",
        new_pwd: "Nieuw wachtwoord",
        submit: "Send",
        update_fail_server:
            "Failed to reset! The server is down for maintenance. Please try again later.",
        update_fail_net:
            "Failed to reset! Please check your internet connection and try again.",
        update_success: "Password reset successfully",
    },
    psersonal: {
        sidebarItems: [
            "Persoonlijke informatie",
            "Abonnement",
            "Apparaten beheren",
            "Facturatie",
            "Ondersteuning contacten",
            "Toegevoegde waarde diensten",
            "Functionaliteit sub-account",
            "Wachtwoord wijzigen",
            "Winkelwagen",
        ],
    },
    userInfoModule: {
        edit: "bewerken",
        name: "Naam",
        update: "Bijwerken",
        reg_time: "Registratietijd",
        updatePwd: {
            forgot: "Vergeten",
            current_pwd: "Huidig wachtwoord",
            current_pwd_p: "Voer het huidige wachtwoord in",
            new_pwd_p: "Voer een nieuw wachtwoord in",
            confirm_pwd_p: "Herhaal het nieuwe wachtwoord",
            update_btn: "Wachtwoord bijwerken",
        },
        update_success: "Naam succesvol gewijzigd",
        pwd_update_success:
            "Wachtwoord succesvol gewijzigd, log alstublieft opnieuw in.",
        profile_update_success: "Avatar succesvol gewijzig",
        destroy: "Account verwijderen",
        destroy_tip: "Weet u zeker dat u uw account wilt verwijderen?",
        destroy_tip2:
            "Deze operatie zal uw account en bijbehorende gegevens permanent verwijderen. De functies die zijn geactiveerd onder uw account zullen na succesvol uitloggen niet beschikbaar zijn. Ga alstublieft voorzichtig te werk!",
        destroy_success: "Uitloggen van account gelukt!",
        pwd_wrong: "Onjuist gebruikerswachtwoord!",
        have_sub:
            "U kunt geen abonnement annuleren met een normale verlengingsstatus. Pauzeer het abonnement eerst!",
    },
    subscription: {
        stop_text:
            "U heeft het lidmaatschapssubscriptieplan voor {plan} gepauzeerd.",
        cancel_text: "Het lidmaatschapsabonnement van je {plan} is opgezegd.",
        canceling:
            "Uw premium lidmaatschapsabonnement voor {plan} wordt momenteel geannuleerd.",
        renew: "Hervat abonnement",
        renew_sucess: "Abonnement succesvol hervat!",
        expire_date: "Vervaldatum",
        status_text: "Abonnementsstatus",
        leave: "Verlaten",
        purchase_date: "Aankooptijd",
        end_date: "Volgende betaling",
        price: "Prijs",
        num: "Ondersteunde apparaten",
        account_status: "Accountstatus",
        no_subscription: "U heeft momenteel geen abonnementen, ",
        recommend: "misschien bent u geïnteresseerd in de volgende inhoud",
        learn_more: "Meer informatie",
        free_download: "Gratis downloaden",
        trial_limit: "Beperkingen tijdens de proefperiode",
        blocked_on: "Geblokkeerd op",
        trial_start: "Starttijd van de proefperiode",
        start_subscription: "Abonnement starten",
        is_trial: "U bevindt zich momenteel in de proefstatus",
        manage_btn: "Abonnement beheren",
        change_payment: "Betaalgegevens wijzigen",
        payment_detail: "Betaalgegevens",
        leave_tip: "Weet u zeker dat u het familieabonnement wilt verlaten?",
        leave_success: "U heeft het familieabonnement met succes verlaten!",
        singular_device_num:
            "{num1} {type}-apparaten beschikbaar, {num2} geactiveerd",
        plural_device_num:
            "{num1} {type}-apparaten beschikbaar, {num2} geactiveerd",
        see_detail: "Details bekijken",
        active: "Actief",
        pause: "Pauze",
        overdue: "(Achterstallig)",
        update_payment_success: "Betalingsmethode succesvol gewijzigd",
        being_processed: "Verwerking",
    },
    subaccount: {
        invite: "Mensen uitnodigen om mee te doen",
        send_invite: "Uitnodiging verzenden",
        manage_title: "Familieleden beheren",
        remove: "Verwijderen",
        send_success:
            "U heeft succesvol een uitnodigingsmail verzonden, wachtend op bevestiging van de andere partij!",
        remove_user: "Weet u zeker dat u de gebruiker {name} wilt verwijderen?",
        all_product: "Alle producten",
    },
    billing: {
        excl_tax: "(excl. omzetbelasting)",
        view_receipt: "Bon bekijken",
        all_time: "De hele tijd",
        refunded: "Terugbetaald",
    },
    manage_devices: {
        reset_all_code: "Alle apparaten resetten",
        reset_a_code: "Huidig apparaat resetten",
        all_reset_success: "U heeft alle apparaten succesvol gereset!",
        a_reset_success: "U heeft {name} succesvol gereset!",
        num_tip:
            "Er is nog maar {num} resterende resetmogelijkheid voor dit jaar.",
        a_warning_text: "Weet u zeker dat u {name} wilt resetten?",
        all_warning_text: "Weet u zeker dat u alle apparaten wilt resetten?",
        device_name: "Toestelnaam",
        system: "Systeem",
        reg_time: "Registratietijd",
        using_device: "U gebruikt {num1} van de {num2} apparaten",
        all: " Alle",
        residue_num: "Resterende resetpogingen",
        reset_history: "Tijd van resetgeschiedenis",
        reset_fail_9:
            "Reset mislukt, geen overeenkomende gegevens om te resetten",
        reset_fail_10:
            "Reset mislukt, overschrijding van het limiet. Als u vragen heeft, neem contact op met de ondersteuning.",
    },
    contact: {
        support_center: "Ondersteuningscentrum",
        support_tip: "Vind hier wat je nodig hebt",
        feedback: "Feedback",
        submit: "Verzenden",
        feedback_fail:
            "Feedback kon niet worden verzonden, probeer het later opnieuw!",
        feedback_success: "Bedankt voor je feedback!",
        enter_content: "Voer feedback in",
        content_placeholder: "Laat hier uw suggesties of vragen achter.",
        select: "Selecteer",
    },

    dm_home: {
        logout: "Uitloggen",
    },
    dm_login: {
        login_desc: "Welkom terug, log alstublieft in op uw account.",
        captcha_pl: "Voer de verificatiecode in",
        or: "of",
        reg: "Registreren",
        enter_tip: "Voer uw {fieldName} in.",
        enter_email: "Voer uw e-mailadres in",
        enter_pwd: "Voer uw wachtwoord in",
        captcha_limit: "De lengte van de verificatiecode is 4",
        login_expired:
            "Om de veiligheid van uw account te beschermen, moet u uw wachtwoord opnieuw invoeren.",
        account_pwd_err: "Inlogaccount- of wachtwoordfout!",
    },
    dm_reg: {
        create: "Account aanmaken",
        fill_form:
            "Vul het onderstaande formulier in om een nieuw account aan te maken.",
    },
    dm_reset_pwd: {
        reset: "Herstel uw wachtwoord",
        reset_pwd: "Wachtwoord resetten",
        reset_tip:
            "Voer uw e-mailadres in en we sturen u instructies over hoe u uw wachtwoord kunt herstellen.",
        back_login: "Terug naar inloggen",
        email_error: "E-mailadres bestaat niet",
        reset_success: "U heeft succesvol uw wachtwoord opnieuw ingesteld.",
        email_had_sent:
            "De e-mail voor het resetten van het wachtwoord is verstuurd naar de mailbox, reset alstublieft uw wachtwoord!",
        no_sign_up:
            "Er is nog geen account voor dit e-mailadres, maak alstublieft een account aan. ",
    },
    manageSubscription: {
        cancel_sub: "Pauzeer abonnement",
        tip: "Als u uw abonnement nu pauzeert, kunt u {name} blijven gebruiken tot het einde van de huidige factureringscyclus.",
        cancel_success: "Abonnement succesvol gepauzeerd.",
        cancel_text:
            "U heeft het lidmaatschapssubscriptieplan voor {productName} succesvol gepauzeerd. U kunt echter {productName} blijven gebruiken tot de laatste dag van uw abonnement.",
        are_you_sure: "Weet u zeker dat u het abonnement wilt pauzeren?",
        request_success: `Uw verzoek is succesvol verzonden; ons klantenserviceteam zal het binnen 48 uur verwerken. Controleer uw e-mail van {officialEmail}.`,
        title: "Abonnement beheren",
        desc: "Uw huidige plan is:",
        change_methods: "Abonnementsmethode wijzigen",
        choose_system_tip: "Selecteer het apparaatsysteem dat u nodig heeft",
        choose_num_tip: "Selecteer het aantal apparaten dat u nodig heeft",
        enter_num: "Voer het aantal apparaten in dat u nodig heeft",
        click_enter: "Klik om in te voeren",
        confirm: "Bevestigen",
        cancel: "Annuleren",
        choose_card_tip: "Selecteer het betaalkaartnummer",
        add_card: "Voeg kaartnummer toe",
        choose_way_tip: "Selecteer de gewenste abonnementsmethode",
        discounts_tip: "U heeft korting gekregen",
        total: "Totaal:",
        excl_tax: "(excl. omzetbelasting)",
        be_counting: "Prijs berekenen",
        end_tip: "Uw vervaldatum is:",
        thank_text:
            "Nogmaals hartelijk dank voor uw voortdurende steun. We kijken uit naar uw volgende bezoek!",
        back: "Terug naar het abonnement",
        update_success: "Abonnementswijziging succesvol",
        pay_btn: "Nu betalen",
        monthy: "Maandelijks",
        annual: "Jaarlijks",
        life_time: "Levenslang",
        more: "Meer",
        pay_tip:
            "Betaalmethode wijzigen: Het platform zal 0,01 van de nieuwe kaart afschrijven, en bij succesvolle afschrijving kan deze worden gekoppeld. Vervolgens wordt het bedrag teruggestort op de oorspronkelijke betaalmethode.",
        is_add: "Nieuwe kaart toevoegen",
        is_set: "Nieuwe kaart instellen",
    },
    receipt: {
        title: "Ontvangstbewijs voor {name}",
        back: "Terug",
        receipt_text: "Ontvangstbewijs",
        amount_paid: "Betaald bedrag",
        date: "Ontvangstbewijsdatum",
        pay_method: "Betaalmethode",
        discount: "Korting",
        discount_tip: "De vermelde items zijn inbegrepen in de prijs",
        contact_tip:
            "Als u hulp nodig heeft met {brand}, neem dan contact met ons op",
        thank: "Dank je wel",
        item: "Items en Omschrijving",
        qty: "Hoeveelheid",
        price: "Prijs",
        amount: "Bruto Opnamebedrag",
        subtotal: "Subtotaal",
        total: "TOTAAL",
    },
    order_complete: {
        title: "Bestelling voltooid!",
        desc: "Bedankt voor uw aankoop! Een leveringsmail wordt binnenkort naar het e-mailadres van de klant gestuurd.",
        order_id: "Uw bestelnummer",
        price: "Totaal bestelling",
        name: "Productnaam",
        delivery: "Levering",
        electronic: "Elektronisch",
        unit_price: "Stuksprijs",
        quantity: "Hoeveelheid",
        final: "Gereduceerde Prijs",
        detail: "Bestelgegevens",
        license_email: "Gelicentieerde e-mail",
        license_tip: "De licentiesleutel wordt opgenomen in de leveringsmail.",
        contact_text:
            "Als u de leveringsmail niet heeft ontvangen, neem dan contact op met de klantenservice:",
    },
    popup: {
        ok: "Ok",
        cancel: "Annuleren",
        confirm: "Bevestigen",
        options: ["Softwareproblemen", "Prijs", "Overig"],
    },
    add_new_card: {
        please_fill: "Gelieve de informatie in te vullen",
    },
};
