<template>
    <div class="login-right">
        <p class="title">{{ $t("forget.title") }}</p>
        <p class="login-tip">{{ $t("forget.tip") }}</p>
        <el-form
            @validate="handleValidation"
            ref="forgetForm"
            :rules="forgetRules"
            :model="forgetInfo"
            @submit.native.prevent
        >
            <el-form-item prop="email">
                <el-input
                    class="input-email"
                    v-model="forgetInfo.email"
                    :placeholder="$t('common.email')"
                ></el-input>
            </el-form-item>
            <el-form-item prop="_captcha" v-if="needCaptcha">
                <el-input
                    class="input-captcha"
                    v-model="forgetInfo._captcha"
                    :placeholder="'请输入验证码'"
                >
                    <img
                        loading="lazy"
                        slot="suffix"
                        :src="captchaUrl"
                        width="120px"
                        height="40px"
                        alt="captcha"
                    />
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="active"
                    :style="{
                        width: '390px',
                    }"
                    @click="handleForget"
                    native-type="submit"
                    v-loading="isLoading"
                    >{{ isLoading ? "" : $t("common.send") }}</el-button
                >
            </el-form-item>
        </el-form>
        <p
            class="to-login"
            v-html="$t('forget.backToLogin', { login: $t('forget.login') })"
        ></p>
    </div>
</template>

<script>
import {
    validateEmail,
    validateCaptcha,
    isJSONString,
} from "@/utils/formValidation.js";
import { sendResetPwdEamil } from "@/api/login.js";
import { showMessage } from "@/utils/message.js";

export default {
    name: "forgetPassword",
    components: {},
    data() {
        return {
            forgetInfo: {
                email: "",
                _captcha: "",
            },
            forgetRules: {
                email: [
                    {
                        required: true,
                        validator: validateEmail,
                        trigger: "blur",
                    },
                ],
                _captcha: [
                    {
                        validator: validateCaptcha,
                        trigger: "blur",
                    },
                ],
            },
            isLoading: false,
            needCaptcha: false,
            count: 0,
        };
    },
    computed: {
        captchaUrl() {
            return `${window.reqUrl}/api/user/captcha`;
        },
    },
    methods: {
        handleForget() {
            this.$refs.forgetForm.validate((res) => {
                if (res) {
                    this.isLoading = true;
                    sendResetPwdEamil({
                        ...this.forgetInfo.email,
                        brand: "videohunter",
                    })
                        .then((res) => {
                            showMessage(res.message, "success", 0);
                            this.isLoading = false;
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            this.count++;
                            if (this.count >= 3) {
                                this.needCaptcha = true;
                                return;
                            }
                            const errMsg = err.response
                                ? err.response.data.message
                                : err.message;
                            if (errMsg == "Parameter error") {
                                showMessage("邮箱无效", "error");
                            }
                        });
                }
            });
        },
        changePage(name) {
            this.$parent.changePage(name);
        },
        handleValidation(prop, isValid, errorMessage) {
            if (!isValid) {
                const filteredChildren = this.$refs.forgetForm.$children.filter(
                    (item) => item.prop === prop
                );
                filteredChildren[0].validateMessage = isJSONString(errorMessage)
                    ? this.$t(JSON.parse(errorMessage).t)
                    : errorMessage;
            }
        },
    },
    mounted() {
        document.querySelector(".loginLink").addEventListener("click", () => {
            this.changePage("login");
        });
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/login.scss";

::v-deep {
    .to-login {
        margin-top: 20px;
        a {
            margin-left: 4px;
            color: #3b7fff;
            text-decoration: none;
            line-height: 20px;
            cursor: pointer;
        }
    }
}
</style>
